import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useQuery, useMutation } from 'react-query';
import { getMember, updateMember } from 'services/api/member';

export const useGetMember = () => {
  const { uuid } = useAppContext();
  const { loggedInUser } = useUserContext();

  return useQuery(
    ['getMember', { uuid, token: loggedInUser?.id_token }],
    getMember(uuid, loggedInUser?.data?.sub, loggedInUser?.id_token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useUpdateMember = (errorCallback: () => void) => {
  localStorage.setItem('isUpdatingMember', 'true');
  return useMutation(
    // eslint-disable-next-line
    (payload: Record<string, unknown>) => updateMember(
        String(payload.uuid),
        String(payload.memberUuid),
        String(payload.token),
        JSON.stringify(payload.body),
      ),
    {
      onSuccess: (e) => {
        if (e?.error) errorCallback();
      },
    },
  );
};
