const navLinks = [
  {
    title: 'home',
    name: 'Home',
    path: '/',
    submenu: [],
  },
  {
    title: 'topics',
    name: 'Topics',
    path: '/topics',
    // submenu: [
    //   {
    //     title: 'Topic 1',
    //     name: 'Topic 1',
    //     path: 'topics/:id',
    //   },
    // ],
    submenu: [],
  },
  {
    title: 'tips',
    name: 'Posts',
    path: '/posts',
    // submenu: [
    //   {
    //     title: 'Post 1',
    //     name: 'Post 1',
    //     path: 'posts/:id',
    //   },
    // ],
    submenu: [],
  },
  {
    title: 'directories',
    name: 'Directories',
    path: '/directories',
    // submenu: [
    //   {
    //     title: 'Directory 1',
    //     name: 'Directory 1',
    //     path: 'directories/:id',
    //   },
    // ],
    submenu: [],
  },
  {
    title: 'courses',
    name: 'Courses',
    path: '/courses',
    submenu: [],
  },
  {
    title: 'action_lists',
    name: 'Lists',
    path: '/action-lists',
    submenu: [],
  },
  {
    title: 'about',
    name: 'About',
    path: '/about',
    submenu: [],
  },
];

export function getNavLinks() {
  return navLinks;
}
