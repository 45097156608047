import { request, patchRequest } from './app';
import { ILinkedSites } from '../../pages/MyAccount/MyAccount';

export const subscribe = async (uuid?: string | null, token?: string) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/apps/${uuid ?? ''}/subscribe`,
    'POST',
    token,
  );

  if (!response?.ok) {
    throw new Error('Unable to become a member.');
  }

  return response;
};

export const unsubscribe = async (uuid?: string | null, token?: string) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/apps/${uuid ?? ''}/unsubscribe`,
    'POST',
    token,
  );

  if (!response?.ok) {
    throw new Error(
      'Unable to Unlink Account. You are a content creator for this site.',
    );
  }

  return response;
};

export const unlockCode = async (
  uuid?: string | null,
  token?: string,
  body?: string,
) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/apps/${uuid ?? ''}/track_now`,
    'POST',
    token,
    body,
  );

  const result = response.json();

  return await result;
};

export const updateAccountInfo = async (
  appUuid?: string | null,
  memberUuid?: string | null,
  token?: string,
  body?: string,
) => {
  const response = await patchRequest(
    `${String(process.env.REACT_APP_API)}/apps/${appUuid ?? ''}/members/${
      memberUuid ?? ''
    }`,
    'PATCH',
    token,
    body,
  );

  return await response.json();
};

export const getLinkedAccounts =
  (uuid: string, memberUuid: string, token?: string) =>
  async (): Promise<ILinkedSites[]> => {
    const response = await request(
      `${String(process.env.REACT_APP_API)}/members/${memberUuid}/linked_sites`,
      'GET',
      token,
    );

    return await response.json();
  };

export const resetPassword = async (token?: string, body?: string) => {
  const response = await request(
    `${String(process.env.REACT_APP_SSO_HOST)}/api/v1/users/reset_password`,
    'POST',
    token,
    body,
  );

  const result = response.json();

  return await result;
};

export const deleteAccount = async (memberUuid: string, token?: string) => {
  const response = await request(
    `${String(process.env.REACT_APP_API)}/members/${memberUuid}`,
    'DELETE',
    token,
  );

  const result = response.json();

  return await result;
};
