import * as React from 'react';
import { FC } from 'react';
// import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useGetMember } from 'services/queries/member';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useGetMemberContentByType } from 'services/queries/content';

interface IMyContent {
  handleMyAccount: () => Promise<void>;
  handleMyTag: (url: string) => void;
}

const MyContent: FC<IMyContent> = ({ handleMyAccount, handleMyTag }) => {
  // const { loggedInUser, isLoggedIn } = useUserContext();
  const {
    setup: {
      colors,
      header: { menu },
    },
    about: { platform_version },
    uuid: appUuid,
    config: { defaultLabels },
    baseURL,
  } = useAppContext();
  const { loggedInUser } = useUserContext();
  const label_overrides = menu?.label_overrides;
  // {
  //   name: default label
  //   label: override label
  //   type: system type
  //   disabled: module status
  // }
  const contentTypes = [
    {
      type: 'videos',
      label: defaultLabels.videos,
    },
    {
      type: 'tips',
      label:
        label_overrides?.tips && label_overrides?.tips !== ''
          ? label_overrides?.tips
          : defaultLabels.tips,
      disabled:
        !menu?.enabled_modules.includes('tips') ||
        parseFloat(platform_version) < 9.0,
    },
    {
      type: 'articles',
      label: defaultLabels.articles,
    },
    {
      type: 'lessons',
      label: defaultLabels.lessons,
    },
    // {
    //   name: 'Journals',
    //   url: `${String(baseURL)}/journals`,
    //   label: label_overrides.journals,
    // },
    {
      type: 'audios',
      label: defaultLabels.audios,
    },
    {
      type: 'questions',
      label: defaultLabels.questions,
    },
    {
      type: 'resources',
      label: defaultLabels.resources,
    },
    {
      type: 'products',
      label: defaultLabels.products,
    },
    {
      type: 'documents',
      label: defaultLabels.documents,
      disabled: parseFloat(platform_version) < 8.5,
    },
    {
      type: 'action_lists',
      label:
        label_overrides?.action_lists && label_overrides?.action_lists !== ''
          ? label_overrides?.action_lists
          : defaultLabels.action_lists,
      disabled:
        !menu?.enabled_modules.includes('action_lists') ||
        parseFloat(platform_version) < 8.5,
    },
  ];

  const availableContentTypes = contentTypes.filter((_) => {
    // Exclude if module is disabled
    if (_.disabled) return false;

    const { data: rawContent } = useGetMemberContentByType(
      String(_.type),
      String(appUuid),
      String(loggedInUser?.id_token),
    );

    // Exclude if no content
    if (!rawContent) {
      return false;
    }

    // filter for active and release date
    const availableContent = rawContent.filter((content) => {
      const todayDate = new Date();
      // display content with blank release_date
      const releaseDate =
        content.release_date === ''
          ? todayDate
          : new Date(content.release_date);
      if (['tips', 'lessons'].includes(_.type))
        return content.active && todayDate >= releaseDate;

      return todayDate >= releaseDate;
    });

    // Exclude if no available content
    if (!availableContent) {
      return false;
    }

    if (availableContent.length > 0) return true;

    return false;
  });

  const contentButtons = availableContentTypes.map((_, index) => {
    return (
      <div className="w-1/2 p-1" key={`my-content-${_.type}-${String(index)}`}>
        <a
          className="rounded-full py-2 px-8 text-white w-full h-16 lg:h-auto justify-center items-center flex text-center"
          style={{ backgroundColor: colors.medium }}
          key={_.label}
          href={`${String(baseURL)}/my/${_.type}`}
        >
          <span className="text-xs md:text-sm lg:text-base">{_.label}</span>
        </a>
      </div>
    );
  });

  // get member tags
  const { data: member } = useGetMember();
  const tags = member?.tags.map((tag: { name: string; id: string }) => {
    // return tag.name;
    return (
      <option value={`${String(baseURL)}/tag/${tag.id}`} key={tag.id}>
        {tag.name}
      </option>
    );
  });
  const handleTagChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    handleMyTag(e.currentTarget.value);
  };
  return (
    <div className="w-full">
      <div className="font-bold text-2xl text-center py-2 my-2 uppercase border-b-2">
        My Content
      </div>
      <div className="flex flex-wrap">{contentButtons}</div>
      <div className="py-2">
        <select className="w-full p-2 border-2" onChange={handleTagChange}>
          <option value="">-- View By Content Tag--</option>
          {tags}
        </select>
      </div>
      <div className="text-center">
        <button
          type="button"
          className="inline-block text-center text-underline"
          style={{ color: colors.dark }}
          onClick={handleMyAccount}
        >
          <span className="underline">
            Manage{' '}
            <strong>
              <span className="underline">A</span>CTION
            </strong>
            ERA Account
          </span>
        </button>
      </div>
    </div>
  );
};

export default MyContent;
