import { FC, useEffect, useRef } from 'react';
import smiley from 'assets/images/smiley.png';
import { CgClose } from 'react-icons/cg';
import { useModalContext } from 'provider/ModalProvider/ModalProvider';
import { UnsubscribePromt } from 'components/modules/Header/Header';
import { useNavigate } from 'react-router-dom';
import { useDefaultColorValue } from 'services/queries/app';
import { MyContentModal } from 'components/modules/MyContent/MyContentModal';
import { useAppContext } from '../../../../provider/AppProvider/AppProvider';

export interface ILoginSideBar {
  rightNavbarOpen: boolean;
  creatorName: string;
  handleLogout: () => Promise<void>;
  setRightNavbarOpen: (str: boolean) => void;
}

const LoginSidebar: FC<ILoginSideBar> = ({
  rightNavbarOpen,
  creatorName,
  handleLogout,
  setRightNavbarOpen,
}) => {
  const { isSubscribed, baseURL, username } = useAppContext();
  const colors = useDefaultColorValue();
  const rightNavbarRef = useRef<HTMLInputElement>(null);
  const { showModal } = useModalContext();
  const navigate = useNavigate();

  useEffect(() => {
    function handleClickOutside(ev: MouseEvent) {
      if (
        rightNavbarRef?.current != null &&
        !rightNavbarRef.current.contains(ev.target as Element)
      ) {
        setRightNavbarOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [rightNavbarRef]);

  return (
    <>
      <button
        className={rightNavbarOpen ? 'fixed top-4 right-40 z-50' : 'hidden'}
        type="button"
        onClick={() => setRightNavbarOpen(false)}
      >
        <CgClose size={25} color="#FFF" />
      </button>
      <div
        className={`top-0 right-0 z-50 w-36 bg-white text-white fixed flex flex-col overflow-y-auto h-full ${
          rightNavbarOpen ? 'flex' : 'hidden'
        }`}
        ref={rightNavbarRef}
      >
        <div className="w-full py-3 relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start pt-6">
          <div className="w-full flex justify-center items-center">
            <div className="w-fit h-fit flex flex-col justify-center items-center rounded-2xl border border-gray-300 bg-white px-4">
              <img src={smiley} alt="logged in" className="w-[40px] h-[40px]" />
              <span className="p-2 text-black">{creatorName}</span>
            </div>
          </div>
        </div>
        <div>
          <ul
            style={{
              color: colors.dark,
            }}
            className="text-center"
          >
            <button
              type="button"
              className="w-full"
              onClick={() => {
                setRightNavbarOpen(false);
                showModal(
                  <MyContentModal
                    handleMyAccount={async () => {
                      navigate(`${String(baseURL)}/my-account`);
                    }}
                    handleTagChange={async (url: string) => {
                      navigate(url);
                    }}
                  />,
                );
              }}
            >
              <li>My Content</li>
            </button>
            <a
              href={`${String(baseURL)}/my-account`}
              target="_blank"
              className="w-full mb-2"
              rel="noreferrer"
            >
              <li
                style={{
                  color: colors.dark,
                }}
              >
                My Account
              </li>
            </a>
            {isSubscribed ? (
              <button
                type="button"
                className="w-full"
                onClick={() => {
                  setRightNavbarOpen(false);
                  showModal(<UnsubscribePromt username={username ?? ''} />);
                }}
              >
                <li>Unlink Account</li>
              </button>
            ) : null}
            <button
              type="button"
              className="w-full mb-2"
              onClick={async () => await handleLogout()}
            >
              <li>Logout</li>
            </button>
          </ul>
        </div>
      </div>
    </>
  );
};

export default LoginSidebar;
