import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useDefaultColorValue } from 'services/queries/app';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { unsubscribe } from 'services/api/user';
import { useModalContext } from 'provider/ModalProvider/ModalProvider';
import { useUpdateMember } from 'services/queries/member';
import { FiLogIn } from 'react-icons/fi';
import { IoMdPin } from 'react-icons/io';
import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oauth2-pkce';
import jwtDecode from 'jwt-decode';
import { IAuthData } from 'provider/UserProvider/UserProvider.interface';
import Navbar from '../NavBar';
import { StyledLightContainer, StyledLink } from './Header.styles';
import { useUserContext } from '../../../provider/UserProvider/UserProvider';
import LoginDropdown from '../NavBar/LoginDropdown/LoginDropdown';
import { MyContentModal } from '../MyContent/MyContentModal';
import TImezoneModal from '../Timezone/TImezoneModal';

const Header = () => {
  const { authService } = useAuth();
  const { id_token } = authService.getAuthTokens();
  const navigate = useNavigate();
  const { light, medium, dark } = useDefaultColorValue();

  const {
    uuid,
    username,
    setup: {
      header: { tagline },
    },
    baseURL,
  } = useAppContext();

  const showUpdateAccountErrorPrompt = () => {
    return <span>Error</span>;
  };

  const { mutateAsync: handleUpdateMember } = useUpdateMember(
    showUpdateAccountErrorPrompt,
  );
  const { logout, loggedInUser, isLoggedIn, login, setLoggedInUser } =
    useUserContext();
  const { showModal, closeModal } = useModalContext();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezoneInStorage = localStorage.getItem('timezone') ?? null;

  const handleUpdateTimezone = async () => {
    await handleUpdateMember({
      uuid,
      memberUuid: loggedInUser?.data?.sub,
      token: loggedInUser?.id_token,
      body: {
        data: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    });

    const res = await authService.fetchToken(
      authService.getAuthTokens().refresh_token,
      true,
    );
    const { id_token: token } = res;
    const tokenData = jwtDecode(token) as IAuthData;
    setLoggedInUser({ id_token: token, data: tokenData });
    localStorage.setItem('timezone', timezone);
  };

  useEffect(() => {
    if ((id_token ?? '') !== '') {
      const data: { timezone: string } = jwtDecode(id_token);

      if (!data.timezone && uuid && !timezoneInStorage) {
        void handleUpdateTimezone();
      }
    }
  }, [id_token]);

  useEffect(() => {
    if ((id_token ?? '') !== '') {
      const data: { timezone: string } = jwtDecode(id_token);

      if (data.timezone) return;
    }

    const isUpdatingMember = localStorage.getItem('isUpdatingMember') ?? null;
    if (!timezoneInStorage && loggedInUser?.data?.sub && isUpdatingMember) {
      showModal(<TImezoneModal handleMyAccount={handleMyAccount} />);
    }
  }, [localStorage]);

  const name =
    localStorage?.getItem('name')?.split(' ') ??
    loggedInUser?.data?.name.split(' ');
  const lastName =
    typeof name !== 'undefined' && name?.length > 1
      ? ` ${name?.[name.length - 1]?.slice(0, 1)}.`
      : '';
  const creatorName = `${name?.[0] ?? ''}${lastName}`;

  const handleLogout = async () => {
    logout();
  };

  const handleUnSubscription = async () => {
    showModal(<UnsubscribePromt username={username ?? ''} />);
  };

  const handleMyAccount = async () => {
    closeModal();
    navigate(`${String(baseURL)}/my-account`);
  };
  const handleMyContent = async () => {
    showModal(
      <MyContentModal
        handleMyAccount={async () => {
          navigate(`${String(baseURL)}/my-account`);
        }}
        handleTagChange={async (url: string) => {
          navigate(url);
        }}
      />,
    );
  };

  return (
    <div className="bg-white">
      <StyledLightContainer color={light} className="hidden lg:block">
        <div className="container mx-auto">
          <div className="flex justify-between">
            <div className="container mx-auto flex flex-wrap items-center justify-between h-12">
              <header className="text-[#666666] text-lg lg:text-base w-1/2">
                {tagline}
              </header>
              <div className="flex items-center">
                <StyledLink
                  to={`${String(baseURL)}/unlock-code`}
                  color={dark}
                  style={{ color: dark }}
                >
                  <button
                    className="flex items-center mr-4 text-[18px]"
                    type="button"
                  >
                    <IoMdPin color={medium} size={20} />
                    <span
                      style={{ color: dark }}
                      className="ml-1 underline hover:no-underline"
                    >
                      Unlock Code
                    </span>
                  </button>
                </StyledLink>
                {!isLoggedIn && (
                  <button
                    onClick={() => login()}
                    className="flex items-center mr-4 text-[18px]"
                    type="button"
                  >
                    <FiLogIn color={medium} />
                    <span
                      style={{ color: dark }}
                      className="ml-2 underline hover:no-underline"
                    >
                      Login
                    </span>
                  </button>
                )}
                <LoginDropdown
                  handleLogout={handleLogout}
                  creatorName={creatorName}
                  handleUnSubscription={handleUnSubscription}
                  handleMyAccount={handleMyAccount}
                  handleMyContent={handleMyContent}
                />
              </div>
            </div>
          </div>
        </div>
      </StyledLightContainer>
      <Navbar />
    </div>
  );
};

export default Header;

export const UnsubscribePromt = ({ username }: { username?: string }) => {
  const { uuid, baseURL } = useAppContext();
  const { loggedInUser } = useUserContext();
  const modalRef = useRef<HTMLInputElement>(null);

  const { closeModal } = useModalContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    function handleClickOutside(ev: MouseEvent) {
      if (
        modalRef?.current != null &&
        !modalRef.current.contains(ev.target as Element)
      ) {
        closeModal();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  const handleUnsubscribe = async () => {
    return await unsubscribe(uuid, loggedInUser?.id_token);
  };

  const { mutate: onUnsubscribe, isLoading } = useMutation(handleUnsubscribe, {
    onSuccess() {
      window.location.href = `${String(baseURL)}/`;
    },
    onError: (e) => {
      // eslint-disable-next-line no-alert
      alert(e);
      closeModal();
    },
    onSettled: async () => {
      await queryClient.invalidateQueries('create');
    },
  });

  return (
    <div ref={modalRef}>
      <header className="max-w-md text-center">
        {`By unlinking your account from this site you will lose ALL member access to
        content you've been given to. If you'd like to proceed, click Continue;
        if you'd like to keep your membership, click Cancel.`}
      </header>
      <div className="flex gap-2 justify-center mt-4">
        <button
          onClick={() => onUnsubscribe()}
          disabled={isLoading}
          type="button"
          className="ttnk-button"
        >
          Continue
        </button>
        <button onClick={closeModal} type="button" className="ttnk-button">
          Cancel
        </button>
      </div>
    </div>
  );
};

UnsubscribePromt.defaultProps = {
  username: '',
};

// eslint-disable-next-line react/prop-types
