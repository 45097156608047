import { FC } from 'react';
import { IoLocationSharp } from 'react-icons/io5';
import { StyledLink } from 'components/modules/Header/Header.styles';
import { useDefaultColorValue } from 'services/queries/app';
import { getNavLinks } from '../links';
import { ILinkProps } from '../Navbar.interface';
import { useAppContext } from '../../../../provider/AppProvider/AppProvider';
import MenuItem from '../MenuItem';
import { IMenuListProps } from './MenuList.interface';

const MenuList: FC<IMenuListProps> = ({ tagline, handleCloseNavbar }) => {
  const navLinks = getNavLinks();

  const colors = useDefaultColorValue();
  const {
    setup: {
      header: { menu },
    },
    config: { defaultLabels },
    baseURL,
  } = useAppContext();

  //

  menu?.enabled_modules.forEach((menuItem) => {
    const filteredMenuIndex = navLinks.findIndex(
      (link) =>
        link.title === menuItem ||
        link.title.toLowerCase().includes(menuItem.toLowerCase()),
    );
    let filteredMenu = navLinks.filter(
      (link) =>
        link.title === menuItem ||
        link.title.toLowerCase().includes(menuItem.toLowerCase()),
    )[0];

    if (filteredMenu && filteredMenuIndex >= 0) {
      navLinks.splice(filteredMenuIndex, 1);
      filteredMenu = {
        ...filteredMenu,
        name:
          menu?.label_overrides[menuItem].length === 0
            ? defaultLabels[menuItem as keyof typeof defaultLabels]
            : menu?.label_overrides[menuItem],
      };
      navLinks.splice(filteredMenuIndex, 0, filteredMenu);
    }
  });

  return (
    <ul className="flex flex-col lg:flex-row list-none lg:justify-center lg:items-center mb-0">
      {navLinks?.map((link: ILinkProps) => {
        let { name, title } = link;

        const enabled_modules = (menu?.enabled_modules ?? []) as string[];

        // show to the users menu in below
        if (!['about', 'home'].includes(link.title)) {
          // hide disabled menu in menu setup
          if (!enabled_modules.includes(title)) return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(
          menu?.label_overrides ?? {},
        )) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          if (key === link.title && value !== '') name = value;
        }

        return (
          <MenuItem
            name={name}
            path={link.path}
            submenu={link.submenu}
            key={link.title}
            handleCloseNavbar={handleCloseNavbar}
          />
        );
      })}
      <li className="nav-item lg:hidden">
        <h1 className="text-[#666666] text-sm w-full py-2 px-5">{tagline}</h1>
      </li>
      <li className="nav-item lg:hidden">
        <span className="flex-row items-center justify-start p-2 pl-4 flex lg:hidden bg-[#f5f0eb]">
          <IoLocationSharp color={colors.medium} size={22} />
          <StyledLink
            to={`${String(baseURL)}/unlock-code`}
            color={colors.dark}
            style={{ color: colors.dark }}
          >
            Unlock code
          </StyledLink>
        </span>
      </li>
    </ul>
  );
};

export default MenuList;
