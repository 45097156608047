import { FC, ReactNode, useState } from 'react';
import { useDefaultColorValue } from 'services/queries/app';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  size?: string;
}

const Button: FC<ButtonProps> = ({ children, className, size, ...props }) => {
  const colors = useDefaultColorValue();

  const [btnColor, setBtnColor] = useState(colors.medium);
  const handleMouseEnter = () => setBtnColor(colors.dark);
  const handleMouseLeave = () => {
    setIsActive(false);
    setBtnColor(colors.medium);
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    props?.onClick?.(e);
    setIsActive((current) => !current);
  };

  const btnSize = {
    small: 'h-[48px]',
    medium: 'h-[80px]',
  };

  interface btnSizeInterface {
    small: string;
    medium: string;
  }

  return (
    <button
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      type="button"
      className={`rounded-full py-2 w-full md:w-auto md:mx-auto md:max-w-[500px] min-w-[208px] border-none font-bold px-2 sm:px-8 relative flex break-words ttci-break-word items-center text-lg ${String(
        className ?? '',
      )} ${btnSize[`${size ?? 'medium'}` as keyof btnSizeInterface]}`}
      style={{
        ...{ color: 'white' },
        ...props.style,
        backgroundColor: isActive ? colors.dark : btnColor,
        boxShadow: '2px 2px 5px #00000026',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="w-full line-clamp-2">{children}</div>
    </button>
  );
};

export default Button;
