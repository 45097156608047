export const getUrlWithProtocol = (url?: string) => {
  if (
    url?.indexOf('http://') === 0 ||
    url?.indexOf('https://') === 0 ||
    url === ''
  ) {
    return url;
  }

  return `https://${url ?? ''}`;
};
