import { FC, useEffect, useContext } from 'react';
import { AppContext } from 'provider/AppProvider/AppProvider';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import parse from 'html-react-parser';

interface IFavicon extends HTMLElement {
  href?: string;
}

const DEFAULT_FAVICON_PATH = '/actionera.ico';

const SEOFavicon: FC = () => {
  const { name, setup } = useContext(AppContext);
  const iconURL: string = setup.icon ?? DEFAULT_FAVICON_PATH;
  useEffect(() => {
    const favicon: IFavicon = document.getElementById('favicon') as HTMLElement;
    favicon.href = iconURL;
  }, []);

  return (
    <HelmetProvider>
      <Helmet>
        {setup.head_tag ? parse(setup.head_tag) : null}
        <title>{name}</title>
        <link rel="icon" type="image/png" sizes="96x96" href={iconURL} />
        <link rel="apple-touch-icon" href={iconURL} />
      </Helmet>
    </HelmetProvider>
  );
};

export default SEOFavicon;
