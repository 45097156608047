import { axiosRequest, patchRequest } from './app';

export interface IMember {
  id: string;
  name: string;
  password: string;
  blacklisted: boolean;
  timezone?: string;
  tags: [
    {
      name: string;
      code: string;
      category: string;
      id: string;
      send_email: boolean;
    },
  ];
  courses: [
    {
      id: string;
      title: string;
      enroll_date: string;
    },
  ];
  url_tags: [
    {
      url_tag: string;
      link: string;
    },
  ];
}

export const getMember =
  (uuid?: string | null, memberUuid?: string | null, token?: string) =>
  async (): Promise<IMember | null> => {
    if (typeof uuid === 'undefined' || uuid === null) return null;
    if (typeof memberUuid === 'undefined' || memberUuid === null) return null;

    const response = await axiosRequest(
      `${String(process.env.REACT_APP_API)}/apps/${uuid}/members/${memberUuid}`,
      'GET',
      token,
    );

    const responseData = await response.data;

    return responseData.data;
  };

export const getUserDelete = async (
  uuid?: string | null,
  memberUuid?: string | null,
  token?: string,
) => {
  if (typeof uuid === 'undefined' || uuid === null) return null;
  if (typeof memberUuid === 'undefined' || memberUuid === null) return null;

  const response = await axiosRequest(
    `${String(
      process.env.REACT_APP_API,
    )}/apps/${uuid}/members/${memberUuid}/delete_from_app`,
    'DELETE',
    token,
  );

  const result = await response;

  return result.status;
};

export const updateMember = async (
  uuid?: string,
  memberUuid?: string,
  token?: string,
  body?: string,
) => {
  if (typeof uuid === 'undefined' || uuid === null) return null;
  if (typeof memberUuid === 'undefined' || memberUuid === null) return null;

  const response = await patchRequest(
    `${String(process.env.REACT_APP_API)}/apps/${uuid}/members/${memberUuid}`,
    'PATCH',
    token,
    body,
  );

  const { data } = await response.json();

  if (data) {
    const payload = JSON.parse(body!);
    localStorage.setItem('timezone', payload.data.timezone as string);
  }

  return data;
};
