import { FC } from 'react';
import LoaderGIF from 'assets/images/loader.gif';
import { ILoader } from './Loader.interface';

const Loader: FC<ILoader> = ({ isLarge = false }) => {
  return (
    <div
      className={`${
        isLarge ? 'min-h-screen' : 'h-full'
      } flex justify-center items-center`}
    >
      <img
        src={LoaderGIF}
        alt="loader"
        className={`${isLarge ? 'w-48' : 'w-36'}`}
      />
    </div>
  );
};

export default Loader;
