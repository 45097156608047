import { FC, useContext } from 'react';
import { useDefaultColorValue } from 'services/queries/app';
import { AppContext } from 'provider/AppProvider/AppProvider';
import { Header } from './Title.styles';

interface TitleProps {
  fontSize: string;
  children: string | string[];
  fontColor?: string;
  className?: string;
}

const Title: FC<TitleProps> = ({
  fontSize,
  children,
  fontColor,
  className,
}) => {
  const { setup } = useContext(AppContext);
  const colors = useDefaultColorValue();

  return (
    <Header
      color={fontColor ?? colors.dark}
      font={setup?.fonts?.header}
      size={fontSize}
      className={`${String(className ?? '')} break-words`}
    >
      {children}
    </Header>
  );
};

export default Title;

Title.defaultProps = {
  fontColor: undefined,
  className: undefined,
};
