import { useAppContext } from 'provider/AppProvider/AppProvider';
import { ChildrenProps } from 'provider/AppProvider/AppProvider.interface';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { createContext, useContext, useMemo } from 'react';
import { useGetFeaturedContentByUuid } from 'services/queries/content';
import { IFeaturedContext } from './FeaturedProvider.interface';

export const featuredDefaultContext: Partial<IFeaturedContext> = {
  featured: [],
  status: '',
  topFeaturedStatus: '',
  topFeatured: [],
};

export const FeaturedContext = createContext<IFeaturedContext>(
  featuredDefaultContext as IFeaturedContext,
);

const FeaturedProvider = ({ children }: ChildrenProps) => {
  const { uuid } = useAppContext();
  const { loggedInUser } = useUserContext();
  const { status, data: featured } = useGetFeaturedContentByUuid(
    false,
    uuid,
    loggedInUser?.id_token,
  );
  const { status: topFeaturedStatus, data: topFeatured } =
    useGetFeaturedContentByUuid(true, uuid, loggedInUser?.id_token);

  const value = useMemo(
    () => ({
      featured,
      status,
      topFeaturedStatus,
      topFeatured,
    }),
    [featured, status, topFeaturedStatus, topFeatured],
  );

  return (
    <FeaturedContext.Provider value={value as IFeaturedContext}>
      {children}
    </FeaturedContext.Provider>
  );
};

export default FeaturedProvider;
export const useFeaturedContext = () => useContext(FeaturedContext);

FeaturedProvider.defaultProps = {
  uuid: undefined,
};
