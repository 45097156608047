export const isValidHttpUrl = (stringVal: string) => {
  let url;
  try {
    url = new URL(stringVal);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const generateUrl = (val: string) => {
  if (!isValidHttpUrl(val)) {
    const imageVal = val?.split(':')?.[1];
    return `${String(process.env.REACT_APP_API)}/media/${imageVal}`;
  }
  return val;
};

export const decodeMediaURL = (mediaURL: string) => {
  if (mediaURL.startsWith('media:')) {
    const mediaID = mediaURL.replace('media:', '');
    return `${process.env.REACT_APP_API ?? ''}/media/${mediaID}`;
  }
  return mediaURL;
};
export const decodeMediaID = (mediaURL: string) => {
  return mediaURL.replace('media:', '');
};
