import { FC, useState } from 'react';
import { FaTwitterSquare, FaFacebookSquare, FaLinkedin } from 'react-icons/fa';
import {
  RiPinterestFill,
  RiTwitterFill,
  RiFacebookBoxFill,
  RiLinkedinBoxFill,
  RiYoutubeFill,
  RiInstagramFill,
  RiComputerFill,
} from 'react-icons/ri';

import { BsFillEnvelopeFill } from 'react-icons/bs';

interface SampleProps {
  label: string;
  link: string;
  iconColor: string;
  iconHoverColor: string;
  size: number;
}
const SocialIcon: FC<SampleProps> = ({
  label,
  link,
  iconColor,
  iconHoverColor,
  size,
}) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const newLink =
    !link.includes('https://') &&
    !link.includes('http://') &&
    !link.includes('mailto')
      ? link.replace(link, `https://${link}`)
      : link;

  const socialIconItem = (social: string) => {
    switch (social) {
      case 'facebook_url':
        return <RiFacebookBoxFill size={size} />;
      case 'facebook_square_url':
        return <FaFacebookSquare size={size} />;
      case 'pinterest_url':
        return <RiPinterestFill size={size} />;
      case 'twitter_url':
        return <RiTwitterFill size={size} />;
      case 'twitter_square_url':
        return <FaTwitterSquare size={size} />;
      case 'youtube_url':
        return <RiYoutubeFill size={size} />;
      case 'instagram_url':
        return <RiInstagramFill size={size} />;
      case 'linkedin_url':
        return <RiLinkedinBoxFill size={size} />;
      case 'linkedin_square_url':
        return <FaLinkedin size={size} />;
      case 'email':
        return <BsFillEnvelopeFill size={size} />;
      default:
        return <RiComputerFill size={size} />;
    }
  };

  return (
    <div
      key={label}
      className="flex gap-2 flex-wrap"
      style={{
        color: isHovering ? iconHoverColor : iconColor,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <a
        href={newLink}
        target="_blank"
        rel="noreferrer"
        className="text-inherit"
      >
        {socialIconItem(label)}
      </a>
    </div>
  );
};

export default SocialIcon;
