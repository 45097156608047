import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IStyledProps } from './Header.interface';

export const StyledLightContainer = styled('div')<IStyledProps>(
  ({ color }) => ({
    backgroundColor: color,
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
  }),
);

export const StyledLink = styled(Link)<IStyledProps>(({ color }) => ({
  color,
  textDecoration: 'underline',
}));
