import styled from 'styled-components';
import { IStyledBody, IStyledWhatsNewContainer } from './Creator.interface';

export const StyledBody = styled('div')<IStyledBody>(({ font }) => ({
  fontFamily: font,
}));

export const StyledWhatsNewContainer = styled('div')<IStyledWhatsNewContainer>(
  ({ bgColor }) => ({
    backgroundColor: bgColor,
  }),
);

export const CarouselContainer = styled('div')<IStyledWhatsNewContainer>(
  ({ bgColor }) => ({
    backgroundColor: bgColor,
  }),
);
