import { useModalContext } from 'provider/ModalProvider/ModalProvider';
import { useEffect, useRef } from 'react';
import MyContent from './MyContent';

export const MyContentModal = ({
  handleMyAccount,
  handleTagChange,
}: {
  handleMyAccount: () => Promise<void>;
  handleTagChange: (url: string) => void;
}) => {
  const modalRef = useRef<HTMLInputElement>(null);
  const { closeModal } = useModalContext();

  useEffect(() => {
    function handleClickOutside(ev: MouseEvent) {
      if (
        modalRef?.current != null &&
        !modalRef.current.contains(ev.target as Element)
      ) {
        closeModal();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef]);

  const handleRedirectToMyAccount = async () => {
    closeModal();
    setTimeout(() => {
      void handleMyAccount();
    }, 200);
  };

  const handleCloseModalTagChange = (url: string) => {
    closeModal();
    setTimeout(() => {
      handleTagChange(url);
    }, 200);
  };

  return (
    <div
      ref={modalRef}
      className="w-[85vw] lg:w-[50vw] xl:w-[40vw] 2xl:w-[33vw] relative"
    >
      <MyContent
        handleMyAccount={handleRedirectToMyAccount}
        handleMyTag={handleCloseModalTagChange}
      />
      <button
        type="button"
        className="absolute -top-2 right-0"
        onClick={closeModal}
      >
        ✕
      </button>
    </div>
  );
};
