import { BrowserRouter } from 'react-router-dom';
import { FC } from 'react';
import UsernameRoute from './UsernameRoute';
import DomainRoute from './DomainRoute';

export const webHostURL = new URL(
  String(process.env.REACT_APP_WEB_HOST ?? 'http://localhost:3000'),
);

const App: FC = () => {
  return (
    <BrowserRouter>
      {window.location.hostname === webHostURL.hostname ? (
        <UsernameRoute />
      ) : (
        <DomainRoute />
      )}
    </BrowserRouter>
  );
};

export default App;
