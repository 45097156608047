import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import pages from './pages';

const Home = lazy(async () => await import('pages/Home'));
const DeleteUser = lazy(async () => await import('pages/DeleteUser'));

const UsernameRoute = () => {
  return (
    <Routes>
      <Route index element={<Home />} />
      <Route path="blank" element={<div />} />
      <Route path="delete-user/">
        <Route path=":uuid" element={<DeleteUser />} />
      </Route>
      <Route path=":username">{pages}</Route>
    </Routes>
  );
};

export default UsernameRoute;
