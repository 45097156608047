import { useRef, useEffect } from 'react';
import { useModalContext } from 'provider/ModalProvider/ModalProvider';

const TImezoneModal = ({
  handleMyAccount,
}: {
  handleMyAccount: () => void;
}) => {
  const { closeModal } = useModalContext();
  const modalContainer = useRef<HTMLDivElement>(null);

  const onClick = async () => {
    handleMyAccount();
  };

  useEffect(() => {
    const parentElement = modalContainer.current?.parentElement;
    if (parentElement) {
      parentElement.style.boxShadow =
        '1px 1px 25px -1px rgba(132, 132, 132, 0.75)';
      parentElement.style.border = '1px solid rgba(132, 132, 132, 0.5)';
    }
  }, []);

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return (
    <div className="timezone" ref={modalContainer}>
      We have updated your timezone to <strong>{timezone}</strong>.
      <br /> Click the <strong>My Account</strong> button below to change your
      timezone.
      <div className="flex mt-6 justify-center">
        <button
          type="button"
          className="border px-8 py-2 rounded-full mr-4 border-slate-400"
          onClick={() => closeModal()}
        >
          OK
        </button>
        <button type="button" className="ttnk-button" onClick={onClick}>
          My Account
        </button>
      </div>
    </div>
  );
};

export default TImezoneModal;
