import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { IStyledNavbar } from './Navbar.interface';

export const StyledNavbar = styled(NavLink)<IStyledNavbar>`
  color: ${(props) => props.colors?.dark ?? '#000'};
  &:hover {
    color: ${(props) => props?.colors?.medium ?? '#7393B3'};
  }

  &.active {
    color: ${(props) => props.colors?.dark ?? '#000'};
  }
`;

export const StyledButton = styled('button')<IStyledNavbar>`
  color: #fff;
  background-color: ${(props) => props.colors?.medium};
  &:hover {
    background-color: ${(props) => props.colors?.dark};
  }
`;
