import { useAppContext } from 'provider/AppProvider/AppProvider';
import { FC } from 'react';

interface AuthorProps {
  name: string;
  color: string;
  shouldRemoveBy?: boolean;
}

const Author: FC<AuthorProps> = ({ name, color, shouldRemoveBy = false }) => {
  const {
    setup: { fonts },
  } = useAppContext();

  if (name === '' || name === null || name === 'null') return null;

  return (
    <p className="mb-0 text-2xl" style={{ color, fontFamily: fonts.header }}>
      {`${shouldRemoveBy ? '' : 'By'}${name}`}
    </p>
  );
};

export default Author;

Author.defaultProps = {
  shouldRemoveBy: false,
};
