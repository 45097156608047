// Where all page routes are declared

import { Route } from 'react-router-dom';
import { lazy } from 'react';
import Products from './Products';
import SpecialOffer from './SpecialOffer';

const Creator = lazy(async () => await import('./Creator'));
const About = lazy(async () => await import('./About'));
const Profile = lazy(async () => await import('./Profile'));
const Signup = lazy(async () => await import('./SignUp'));
const MyAccount = lazy(async () => await import('./MyAccount'));
const UnlockNow = lazy(async () => await import('./UnlockNow'));
const UnlockSuccess = lazy(async () => await import('./UnlockNowSuccess'));
const PageNotFound = lazy(async () => await import('./PageNotFound'));
// Content
const Content = lazy(async () => await import('./Content'));
const Posts = lazy(async () => await import('./Posts'));
const Lessons = lazy(async () => await import('./Lessons'));
// Courses
const CoursesInfo = lazy(async () => await import('./Courses/Info'));
const CoursesWelcome = lazy(async () => await import('./Courses/Welcome'));
const CourseModules = lazy(async () => await import('./CourseModules'));
const CourseList = lazy(async () => await import('./CourseList'));
const CourseLessons = lazy(async () => await import('./CourseLessons'));
// Directories / Listings / Tagged / My Content
const Topics = lazy(async () => await import('./Topics'));
const Directories = lazy(async () => await import('./Directories'));
const SubDirectory = lazy(async () => await import('./SubDirectory'));
const ContentList = lazy(async () => await import('./ContentList'));
const EmptyContentList = lazy(
  async () => await import('./ContentList/EmptyScreen'),
);
const MyContentType = lazy(
  async () => await import('./MyContent/MyContentType'),
);
const Tagged = lazy(async () => await import('./MyContent/Tagged'));
const Documents = lazy(async () => await import('./Documents'));

// Action Forms
const ActionFormsTopic = lazy(async () => await import('./ActionForms/Topic'));
const ActionFormsProfile = lazy(
  async () => await import('./ActionForms/Profile'),
);
const ActionFormsOffer = lazy(async () => await import('./ActionForms/Offer'));

// Action Lists
const ActionListPage = lazy(
  async () => await import('./ActionList/ActionListPage'),
);
const ActionList = lazy(async () => await import('./ActionList'));
const SubscribedList = lazy(
  async () => await import('./ActionList/SubscribedList'),
);

export default (
  <>
    <Route index element={<Creator />} />
    <Route path="*" element={<PageNotFound />} />

    <Route path="about" element={<About />} />
    <Route path="profile/:uuid" element={<Profile />} />
    <Route path="user/sign_up" element={<Signup />} />

    {/* My Account */}
    <Route path="my-account" element={<MyAccount />} />
    <Route path="unlock-code" element={<UnlockNow />} />
    <Route path="unlock-success" element={<UnlockSuccess />} />

    {/* Content */}
    <Route path=":type/:uuid" element={<Content />} />

    {/* Posts */}
    <Route path="posts" element={<Posts />} />

    {/* Lessons */}
    <Route path="lessons/:uuid" element={<Lessons />} />

    {/* Products */}
    <Route path="products/:uuid" element={<Products />} />

    {/* Offers */}
    <Route path="offers/:uuid" element={<SpecialOffer />} />

    {/* Courses */}
    <Route path="courses/">
      <Route index element={<CourseList />} />
      <Route path=":courseId/info" element={<CoursesInfo />} />
      <Route path=":courseId/modules" element={<CourseModules />} />
      <Route path=":courseId/welcome" element={<CoursesWelcome />} />
    </Route>
    <Route
      path="course_categories/:course_uuid/courses"
      element={<CourseList />}
    />
    <Route path="course-lessons/:id" element={<CourseLessons />} />

    {/* Topics */}
    <Route path="topics" element={<Topics />} />

    {/* Directories */}
    <Route path="directories">
      <Route index element={<Directories />} />
      <Route path=":uuid" element={<SubDirectory />} />
    </Route>

    {/* Listing */}
    <Route path="listing/:topic_uuid">
      <Route index element={<ContentList />} />
      <Route path="empty" element={<EmptyContentList />} />
      <Route path=":sub_topic" element={<ContentList />} />
    </Route>

    {/* My Content */}
    <Route path="my/:content_type" element={<MyContentType />} />

    {/* Tagged */}
    <Route path="tag/:tag_id">
      <Route index element={<Tagged />} />
      <Route path=":content_type" element={<Tagged />} />
    </Route>

    {/* Action Forms */}
    <Route
      path="action-forms/:action_form_uuid/topic"
      element={<ActionFormsTopic />}
    />
    <Route
      path="action-forms/:action_form_uuid/profile"
      element={<ActionFormsProfile />}
    />
    <Route
      path="action-forms/:action_form_uuid/offer"
      element={<ActionFormsOffer />}
    />

    {/* Documents */}
    <Route path="documents/:document_id">
      <Route index element={<Documents />} />
    </Route>

    {/* Action Lists */}
    <Route path="action-lists/">
      <Route index element={<ActionList />} />
      <Route path=":uuid" element={<ActionListPage />} />
    </Route>

    <Route path="subscribed-lists" element={<SubscribedList />} />
  </>
);
