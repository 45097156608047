import {
  ReactNode,
  useContext,
  useState,
  createContext,
  useCallback,
  useMemo,
  useEffect,
  CSSProperties,
} from 'react';
import Modal from 'components/base/Modal';
import { IModalContext } from './ModalProvider.interface';

export const ModalContext = createContext<IModalContext>({
  showModal() {
    // fallback fn
  },
  closeModal() {
    // fallback fn
  },
});

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [content, setContent] = useState<ReactNode | null>(null);
  const [customStyles, setCustomStyles] = useState<CSSProperties | null>(null);

  const showModal = useCallback(
    (modalContent: ReactNode, styles?: CSSProperties) => {
      setContent(modalContent);

      if (styles) setCustomStyles(styles);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setContent(null);

    setCustomStyles({
      padding: 20,
    });
  }, []);

  const contextProps = useMemo(
    () => ({
      showModal,
      closeModal,
    }),
    [],
  );

  useEffect(() => {
    window.onpopstate = () => closeModal();
  }, [window]);

  return (
    <ModalContext.Provider value={contextProps}>
      {Boolean(content) && (
        <Modal isOpen={Boolean(content)} style={customStyles}>
          {content}{' '}
        </Modal>
      )}
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
