import { AppContext } from 'provider/AppProvider/AppProvider';
import { FC, useContext, useState } from 'react';

import { useDefaultColorValue } from 'services/queries/app';
import InnerHTML from 'dangerously-set-html-content';
import defaultImage from 'assets/images/no-picture.png';

interface AuthorProps {
  title: string;
  description?: string;
  image: string;
  targetUrl: string;
}

const FeaturedResource: FC<AuthorProps> = ({
  title,
  description,
  image,
  targetUrl,
}) => {
  const { setup, fallBackImage } = useContext(AppContext);
  const { fonts } = setup;
  const colors = useDefaultColorValue();

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="w-full py-[2.88rem] px-0 sm:px-5"
      style={{ backgroundColor: colors.dark }}
    >
      <div className="flex flex-wrap md:flex-nowrap justify-center items-center container mx-auto">
        <img
          className="object-cover w-60 h-[11.25rem] mr-4"
          src={image !== '' ? image : fallBackImage || defaultImage}
          alt="Featured Resource"
        />
        <div className="p-4 md:p-0 w-full">
          <span
            className="text-2xl md:w-[calc(100%-240px)] flex justify-center items-center text-white"
            style={{ fontFamily: fonts.header }}
          >
            {title}
          </span>

          <InnerHTML
            html={
              description === null ||
              description === '' ||
              typeof description === 'undefined'
                ? '<p></p>'
                : description
            }
            className="inner-html text-justify text-base mt-3.5 tracking- line-clamp-3 text-white w-full break-words ttci-break-word"
          />

          <div className="md:w-[calc(100%-240px)] flex justify-center items-center">
            <a
              href={targetUrl}
              className="font-bold text-base px-4 py-2 rounded-full uppercase"
              style={{
                backgroundColor: isHovering ? colors.medium : '#FFF',
                color: isHovering ? '#FFF' : colors.dark,
                boxShadow: '2px 2px 5px #00000026',
              }}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              MORE...
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedResource;

FeaturedResource.defaultProps = {
  description: undefined,
};
