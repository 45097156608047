import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getContentByUuid,
  getFeaturedContentByType,
  getFeaturedContentByUuid,
  getMemberContentByType,
  getMemberContentByTypeAndTag,
  getOffersByUuid,
  getProfileByUuid,
  subscribeToContent,
  unsubscribeToContent,
} from 'services/api/content';

export const useGetContentByUuid = (uuid?: string, token?: string) => {
  return useQuery(
    ['getContentByUuid', { uuid, token }],
    getContentByUuid(uuid, token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetOfferByUuid = (uuid?: string, token?: string) => {
  return useQuery(
    ['getOfferByUuid', { uuid, token }],
    getOffersByUuid(uuid, token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetProfileByUuid = (uuid?: string, token?: string) => {
  return useQuery(
    ['getContentByUuid', { uuid, token }],
    getProfileByUuid(uuid, token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetFeaturedContentByUuid = (
  top: boolean,
  uuid?: string | null,
  token?: string,
) => {
  return useQuery(
    ['getFeaturedContentByUuid', { uuid, top, token }],
    getFeaturedContentByUuid(top, uuid, token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetFeaturedContentByType = (
  type: string,
  uuid?: string | null,
  token?: string,
) => {
  return useQuery(
    ['getFeaturedContentByType', { uuid, type, token }],
    getFeaturedContentByType(type, uuid, token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetMemberContentByType = (
  type: string,
  uuid?: string | null,
  token?: string,
) => {
  return useQuery(
    ['getMemberContentByType', { uuid, type, token }],
    getMemberContentByType(type, uuid, token),
    {
      staleTime: 1000 * 60 * 5,
      enabled: true,
    },
  );
};

export const useGetMemberContentByTypeAndTag = (
  type: string,
  tag_id: string,
  uuid?: string | null,
  token?: string,
) => {
  return useQuery(
    ['getMemberContentByTypeAndTag', { type, tag_id, uuid, token }],
    getMemberContentByTypeAndTag(type, tag_id, uuid, token),
    {
      staleTime: 1000 * 60 * 15,
      enabled: true,
    },
  );
};

export const useSubscribeToContent = (
  uuid?: string | null,
  errorCallback?: (str: string) => void,
  successCallback?: (str?: string) => Promise<void>,
) => {
  const queryClient = useQueryClient();
  const { loggedInUser } = useUserContext();

  return useMutation(
    async () =>
      await subscribeToContent(String(uuid), String(loggedInUser?.id_token)),
    {
      onSuccess: async (response) => {
        if (response?.error && errorCallback) {
          errorCallback(
            response?.error?.detail || 'Unable to subscribe to content',
          );
        } else if (successCallback && !response?.error) await successCallback();
      },
      onError: (e: { message: string }) => {
        if (errorCallback) errorCallback(e.message);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['subscribeToContentById'],
        });

        void queryClient.invalidateQueries({
          queryKey: ['getContentByUuid'],
        });

        void queryClient.invalidateQueries({
          queryKey: ['getFeaturedContentByType'],
        });
      },
    },
  );
};

export const useUnsubscribeToContent = (
  uuid?: string | null,
  errorCallback?: (str: string) => void,
  successCallback?: () => Promise<void>,
) => {
  const queryClient = useQueryClient();
  const { loggedInUser } = useUserContext();

  return useMutation(
    async () =>
      await unsubscribeToContent(String(uuid), String(loggedInUser?.id_token)),
    {
      onSuccess: async () => {
        if (successCallback) await successCallback();
      },
      onError: (e: { message: string }) => {
        if (errorCallback) errorCallback(e.message);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries({
          queryKey: ['unsubscribeToContentById'],
        });

        void queryClient.invalidateQueries({
          queryKey: ['getContentByUuid'],
        });

        void queryClient.invalidateQueries({
          queryKey: ['getFeaturedContentByType'],
        });
      },
    },
  );
};
