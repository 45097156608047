import { FC } from 'react';
import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useDefaultColorValue } from 'services/queries/app';
import isNil from 'lodash.isnil';
import { generateUrl } from 'utils/url';
import { ILogo } from './Logo.types';

// const Icon: FC<ILogo> = ({ imgSrc, alt }) => {
//   return (
//     <img
//       className="w-[80px] lg:w-[96px] object-contain mx-auto"
//       src={imgSrc}
//       alt={alt ?? 'Brand'}
//     />
//   );
// };

const LogoText: FC<{ text: string; logoTextStyle: undefined | string }> = ({
  text,
  logoTextStyle,
}) => {
  const colors = useDefaultColorValue();

  return (
    <div
      style={{ color: colors.dark }}
      className={`uppercase ${String(logoTextStyle ?? '')}`}
    >
      {text.substring(0, 18)}
    </div>
  );
};

const Img: FC<ILogo> = ({ imgSrc, className, alt }) => {
  return (
    <img
      className={`object-contain ${String(className ?? '')}`}
      src={imgSrc}
      alt={alt ?? 'Brand'}
    />
  );
};

const Logo: FC<ILogo> = ({ className, logoTextStyle }) => {
  const { setup } = useAppContext();

  const { header } = setup;
  const { logo } = header;
  const { logo_url: logoUrl, logo_text: logoText } = logo;

  const isLogoUrlExists = !isNil(logoUrl) && logoUrl !== '';
  if (isLogoUrlExists)
    return (
      <Img imgSrc={generateUrl(logoUrl)} className={className} alt={logoText} />
    );

  // const isIconUrlExist = !isNil(icon) && icon !== '';
  // if (isIconUrlExist) return <Icon imgSrc={icon} alt={logoText} />;

  return <LogoText text={logoText} logoTextStyle={logoTextStyle} />;
};

export default Logo;
