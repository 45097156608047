import { FC, useState } from 'react';
import smiley from 'assets/images/smiley.png';
import { AiFillCaretUp, AiFillCaretDown } from 'react-icons/ai';
import { useDefaultColorValue } from 'services/queries/app';
import { useAppContext } from '../../../../provider/AppProvider/AppProvider';
import { useUserContext } from '../../../../provider/UserProvider/UserProvider';
import LoginDropdownItem from './LoginDropdownItem/LoginDropdownItem';

// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
export interface ILoginDropdown {
  handleLogout: () => Promise<void>;
  handleUnSubscription: () => Promise<void>;
  handleMyAccount: () => Promise<void>;
  handleMyContent: () => Promise<void>;
  creatorName: string;
}

const LoginDropdown: FC<ILoginDropdown> = ({
  handleLogout,
  handleUnSubscription,
  handleMyContent,
  handleMyAccount,
  creatorName,
}) => {
  const colors = useDefaultColorValue();
  const { isSubscribed } = useAppContext();

  const { isLoggedIn } = useUserContext();
  const [hovering, setHovering] = useState(false);

  return (
    <div className="hidden lg:flex h-10">
      <div
        className="group cursor-pointer dropdown relative inline-block rounded-2xl ml-2"
        id="login-dropdown"
        style={{
          boxShadow: hovering ? '2px 2px 5px #00000026' : '',
        }}
      >
        {isLoggedIn ? (
          <button
            type="button"
            className={`lg:min-w-[150px] w-full h-full flex flex-row justify-center items-center group-hover:rounded-t-lg border bg-white px-2 ${
              hovering ? 'border' : 'rounded-lg border-gray-300'
            }`}
            style={{
              borderColor: hovering ? colors.dark : '',
              borderBottom: hovering ? 'none' : '',
            }}
            id="menu-button"
            aria-expanded="true"
            aria-haspopup="true"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
          >
            <img src={smiley} alt="logged in" className="w-[40px] h-[40px]" />
            <span className="px-2">{creatorName}</span>
            {hovering ? (
              <AiFillCaretUp
                size={12}
                color={colors.medium}
                aria-hidden="true"
              />
            ) : (
              <AiFillCaretDown
                size={12}
                color={colors.medium}
                aria-hidden="true"
              />
            )}
          </button>
        ) : (
          <div />
        )}

        {isLoggedIn ? (
          <div
            className="group-hover:block group-hover:rounded-b-lg dropdown-menu absolute hidden h-auto z-10"
            onMouseEnter={() => setHovering(true)}
            onMouseLeave={() => setHovering(false)}
            style={{
              borderBottomColor: colors.dark,
            }}
          >
            <ul className="top-10 lg:min-w-[150px] w-full bg-white shadow mb-0 group-hover:rounded-b-lg">
              {isSubscribed ? (
                <LoginDropdownItem
                  label="My Content"
                  onClick={handleMyContent}
                  isLastItem={false}
                />
              ) : null}
              <LoginDropdownItem
                label="My Account"
                onClick={handleMyAccount}
                isLastItem={false}
              />
              {isSubscribed ? (
                <LoginDropdownItem
                  label="Unlink Account"
                  onClick={handleUnSubscription}
                  isLastItem={false}
                />
              ) : null}
              <LoginDropdownItem
                label="Logout"
                onClick={handleLogout}
                isLastItem={isSubscribed}
              />
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default LoginDropdown;
