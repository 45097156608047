import { QueryClient, QueryClientProvider } from 'react-query';
import AppRoutes from 'pages/index';
import { FC, Suspense } from 'react';
import AppProvider from 'provider/AppProvider/AppProvider';
import { AuthService, AuthProvider } from 'react-oauth2-pkce';
import FeaturedProvider from 'provider/FeaturedProvider/FeaturedProvider';
import UserProvider from 'provider/UserProvider/UserProvider';
import { ModalProvider } from 'provider/ModalProvider/ModalProvider';

// eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, global-require, @typescript-eslint/no-var-requires
window.Buffer = window.Buffer || require('buffer').Buffer;

const queryClient = new QueryClient();

// TODO: Add preloader in suspense callback
const authService = new AuthService({
  clientId:
    process.env.REACT_APP_CLIENT_ID ??
    'igwAyUldGYTy_fM-gxgzm--4BxekBj8d2qIgDQUE8yc',
  location: window.location,
  provider:
    process.env.REACT_APP_PROVIDER ?? 'https://ae-sso.techtank.ca/oauth',
  redirectUri: window.location.origin,
  scopes: ['public', 'profile', 'openid', 'name', 'email'],
  autoRefresh: true,
});

const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider authService={authService}>
        <UserProvider>
          <AppProvider>
            <FeaturedProvider>
              <Suspense fallback={null}>
                <ModalProvider>
                  <AppRoutes />
                </ModalProvider>
              </Suspense>
            </FeaturedProvider>
          </AppProvider>
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export default App;
