import { CSSProperties, FC, ReactNode } from 'react';
import ReactModal from 'react-modal';
import { useAppContext } from 'provider/AppProvider/AppProvider';

interface IModalProps {
  isOpen?: boolean;
  style?: CSSProperties | null;
  children: ReactNode;
}

const Modal: FC<IModalProps> = ({ children, isOpen = false, style = {} }) => {
  const {
    setup: { colors },
  } = useAppContext();
  const baseModalStyle = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '0.76rem',
      transform: 'translate(-50%, -50%)',
      zIndex: 11,
      backgroundColor: colors.light,
      ...style,
    },
  };
  ReactModal.setAppElement('#root');

  return (
    <ReactModal isOpen={isOpen} style={baseModalStyle} ariaHideApp={false}>
      {children}
    </ReactModal>
  );
};

export default Modal;

Modal.defaultProps = {
  isOpen: false,
  style: {},
};
