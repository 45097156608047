import React, { FC, useState } from 'react';
import { useDefaultColorValue } from 'services/queries/app';

export interface ILoginDropdownItem {
  label: string;
  isLastItem: boolean;
  onClick: () => Promise<void>;
}

const LoginDropdownItem: FC<ILoginDropdownItem> = ({
  onClick,
  label,
  isLastItem = false,
}) => {
  const colors = useDefaultColorValue();
  const [dropdownBgColor, setDropdownBgColor] = useState(colors.dark);

  return (
    <button
      type="button"
      className="w-full text-left"
      onClick={async () => await onClick()}
      onMouseEnter={() => setDropdownBgColor(colors.medium)}
      onMouseLeave={() => setDropdownBgColor(colors.dark)}
    >
      <li
        className={`block text-base text-white cursor-pointer p-4 hover:underline border ${
          isLastItem ? 'group-hover:rounded-b-lg' : ''
        }`}
        style={{
          backgroundColor: dropdownBgColor,
          borderColor: colors.dark,
          borderTop: colors.dark,
        }}
      >
        {label}
      </li>
    </button>
  );
};

export default LoginDropdownItem;
