import { useAppContext } from 'provider/AppProvider/AppProvider';
import { useUserContext } from 'provider/UserProvider/UserProvider';
import { useModalContext } from 'provider/ModalProvider/ModalProvider';
import { StyledBody } from 'pages/Creator/Creator.styles';
import Layout from 'components/template/Layout';
import { StyledBgContainer } from 'pages/Content/Content.styles';

export const LoginPrompt = () => {
  const { login } = useUserContext();
  const { baseURL } = useAppContext();

  const handleLogin = async () => login();

  return (
    <div>
      <header className="max-w-md text-center">
        You need to sign up to an ACTIONERA account and/or log in to the website
        before you can view this form.
      </header>
      <div className="flex gap-2 justify-center mt-4">
        <a
          href={`${String(baseURL)}/user/sign_up`}
          target="_blank"
          rel="noreferrer"
        >
          <button type="button" className="ttnk-button">
            Sign Up
          </button>
        </a>
        <button
          onClick={async () => await handleLogin()}
          type="button"
          className="ttnk-button"
        >
          Login
        </button>
      </div>
    </div>
  );
};

export const FormLimitPrompt = () => {
  const { closeModal } = useModalContext();

  const handleLogin = async () => closeModal();

  return (
    <div>
      <header className="max-w-md text-center">
        This form cannot be accepted at this time. Please contact the owner of
        this website for more information.
      </header>
      <div className="flex gap-2 justify-center mt-4">
        <button
          onClick={async () => await handleLogin()}
          type="button"
          className="ttnk-button"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export const PageNotFound = () => {
  const {
    setup: { fonts },
  } = useAppContext();

  return (
    <StyledBody
      font={fonts?.body}
      className="min-h-screen flex justify-center items-center"
    >
      Page not found.
    </StyledBody>
  );
};

export const PageNotFoundLoggedOut = () => {
  const {
    setup: { fonts, colors },
  } = useAppContext();

  const { loggedInUser } = useUserContext();

  if (!loggedInUser?.id_token) {
    return (
      <Layout>
        <StyledBgContainer
          bgColor={colors.light}
          className="flex flex-col justify-between h-full pb-10"
        >
          <p className="p-8 bg-white flex justify-center items-center mx-auto mt-48 min-w-[70vw]">
            You have logged out of this website. You need to login in order to
            view specific content.
          </p>
        </StyledBgContainer>
      </Layout>
    );
  }

  return (
    <StyledBody
      font={fonts?.body}
      className="min-h-screen flex justify-center items-center"
    >
      Page not found.
    </StyledBody>
  );
};
