import useWindowSize from 'hooks/useWindowSize';
import { AppContext } from 'provider/AppProvider/AppProvider';
import {
  useDefaultColorValue,
  useGetAppAboutByUUID,
  useGetUserDataByUsername,
} from 'services/queries/app';
import { FC, useContext } from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SocialIcon from '../SocialIcon';
import { StyledFooterIconContainer } from './Footer.styles';

const Footer: FC = () => {
  const appUserData = useGetUserDataByUsername();
  const appData = useGetAppAboutByUUID();
  const isWebEnabled = appData?.data?.setup?.web;
  const privacy_policy_url = appUserData?.data?.about?.privacy_policy_url;
  const { profile, baseURL } = useContext(AppContext);
  const colors = useDefaultColorValue();
  const { width } = useWindowSize();
  const year = new Date().getFullYear();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="container mx-auto p-8 lg:py-8 lg:px-0 flex flex-col lg:flex-row-reverse gap-2 justify-between items-center">
      <StyledFooterIconContainer
        color={colors.medium}
        className="flex gap-2 flex-wrap justify-center items-center"
      >
        {Boolean(profile.twitter_url) && (
          <SocialIcon
            label="twitter_square_url"
            link={profile.twitter_url}
            iconColor={colors.medium}
            iconHoverColor={colors.dark}
            size={(width ?? 0) >= 1024 ? 32 : 48}
          />
        )}
        {Boolean(profile.facebook_url) && (
          <SocialIcon
            label="facebook_square_url"
            link={profile.facebook_url}
            iconColor={colors.medium}
            iconHoverColor={colors.dark}
            size={(width ?? 0) >= 1024 ? 32 : 48}
          />
        )}
        {Boolean(profile.linkedin_url) && (
          <SocialIcon
            label="linkedin_square_url"
            link={profile.linkedin_url}
            iconColor={colors.medium}
            iconHoverColor={colors.dark}
            size={(width ?? 0) >= 1024 ? 32 : 48}
          />
        )}
        {Boolean(profile.youtube_url) && (
          <SocialIcon
            label="youtube_url"
            link={profile.youtube_url}
            iconColor={colors.medium}
            iconHoverColor={colors.dark}
            size={(width ?? 0) >= 1024 ? 32 : 48}
          />
        )}
        {Boolean(profile.instagram_url) && (
          <SocialIcon
            label="instagram_url"
            link={profile.instagram_url}
            iconColor={colors.medium}
            iconHoverColor={colors.dark}
            size={(width ?? 0) >= 1024 ? 32 : 48}
          />
        )}
        {Boolean(profile.email) && (
          <SocialIcon
            label="email"
            link={`mailto:${profile.email}`}
            iconColor={colors.medium}
            iconHoverColor={colors.dark}
            size={(width ?? 0) >= 1024 ? 32 : 48}
          />
        )}
        <button
          type="button"
          onClick={() => scrollToTop()}
          className="h-12 w-12 lg:h-8 lg:w-8 bg-[#00000029] hover:bg-[#888888] flex justify-center items-center rounded text-white gap-2 flex-wrap"
        >
          <FontAwesomeIcon icon={faChevronUp} size="lg" />
        </button>
      </StyledFooterIconContainer>
      <div className="text-gray-800 text-center text-xs">
        {`Content © ${year} by `}
        <a
          className="underline font-medium"
          style={{ color: colors.dark }}
          href={`${String(baseURL)}/about`}
        >
          {`${profile.name},`}
        </a>
        <span className="italic"> All Rights Reserved</span>.{' '}
        <span className="color-[#888888]">{`• `}</span>
        <a
          className="underline"
          style={{ color: colors.dark }}
          href="http://ActionEra.com"
          target="_blank"
          rel="noreferrer"
        >
          Site by <span className="font-bold">ACTION</span>ERA
        </a>
        {isWebEnabled && privacy_policy_url && (
          <>
            <span className="ml-2.5">{`• `}</span>
            <a
              className="underline"
              style={{ color: colors.dark }}
              href={privacy_policy_url}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </>
        )}
      </div>
    </footer>
  );
};

export default Footer;
