import { Skeleton } from '@mui/material';

const Preloader = () => {
  return (
    <div className="w-full lg:w-[780px] mx-auto">
      <div style={{ aspectRatio: '16/9' }} className="w-full flex items-center">
        <Skeleton height="100%" width="100%" variant="rectangular" />
      </div>
      <div className="mt-4">
        <header className="text-[22px]">
          <Skeleton variant="text" width={130} />
        </header>
        <div className="flex gap-2 text-base">
          <Skeleton variant="text" width={120} />
          <Skeleton variant="text" width={80} />
        </div>
        <p className="mb-0 text-lg">
          <Skeleton variant="text" width="100%" />
          <Skeleton variant="text" width="90%" />
          <Skeleton variant="text" width="95%" />
          <Skeleton variant="text" width="15%" />
        </p>
      </div>
    </div>
  );
};

export default Preloader;
