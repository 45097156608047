import { FC } from 'react';
import { IAudioPlayer } from './AudioPlayer.interface';

/* eslint-disable jsx-a11y/media-has-caption */
const AudioPlayer: FC<IAudioPlayer> = ({ srcLink }) => {
  return (
    <audio controls className="w-full">
      <source src={srcLink} type="audio/mp3" />
      Your browser does not support the
      <code>audio</code> element.
    </audio>
  );
};

export default AudioPlayer;
